.finish-sell {
    .methods {
        text-align: left;
        cursor: pointer;

        .selected {
            border: 2px rgb(30, 70, 30) dotted;
            padding: 5px;
        }
    }

    button {
        margin-top: 10px;
    }
}