.login-page {
    display: flex;
    height: 100%;

    .brand {
        width: 50%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .login-form {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .container {        
            width: 400px;
            padding: 10px;
        }
    }

    @media screen and (max-width: 1100px) {
        .login-form .container {
            width: 100%;
        }
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;

        .brand {
            width: 100%;
            height: 50%;
        }

        .login-form {
            align-self: center;
            justify-content: start;
            width: 100%;
        }
    }
}