#interactive.viewport {
    width: 400px;
    height: 300px;
}

#interactive.viewport canvas,
video {
    width: 400px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
    width: 400px;
    height: 300px;
}