.products {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 80px;
    justify-content: center;

    .product {
        border: 1px solid rgb(202, 202, 202);
        text-align: center;
        margin: 5px;
        width: 250px;
        position: relative;
        padding-bottom: 30px;
        height: 380px;
        margin-top: 10px;

        .title {
            font-weight: bold;
            font-size: 15px;
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 45px;
            padding: 5px;
            overflow: hidden;
            margin-bottom: 5px;
            text-overflow: ellipsis;
        }

        .code {
            display: block;
            font-style: italic;
            color: grey;
        }

        .price {
            font-size: 15px;
        }

        img {
            max-width: 250px;
        }

        button {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    @media screen and (max-width: 550px) {
        .product {
            width: 100%;

            img {
                width: 100%;
                max-width: 100%;
            }
        }            
    }
}

#sellPage {
    text-align: center;
    position: relative;

    .add-sell {
        //position: fixed;
        //z-index: 2;
        left: 0;
        width: 100%;
        padding: 5px;
    }

    .sell-form {
        
        background-color: white;
        justify-content: center;
        display: flex;
    
        .sell-form {
            padding-top: 80px;
            margin-left: 8%;
            position: inherit;
            width: 100%;
        }

        .sell {
            width: 100%;
            //margin-left: 240px;
        }
    }

    .actions {
        display: flex;
        align-items: center;
        flex-direction: column;
        //margin-left: 240px;

        button {
            margin-top: 10px;
            width: 100%;
        }
    }

    @media screen and (max-width: 900px) {
        .sell-form {
            width: 100%;
            margin: 0;
            left: 0;
            margin-top: 5px;
            padding-left: 0;

            .sell {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 350px) {
    #sellPage {

        .sell-form {
            flex-direction: column;

            .sell {
                width: 100%;
                padding: 5px;
            }

            button {
                width: 100%;
            }
        }

        .actions button {
            width: 100%;
        }
    }
    .products {
        margin-top: 80px;
    }
}

.sell-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 80px;
    background-color: rgb(112, 61, 136);
    width: 100%;
    color: white;
    z-index: 999;
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: space-around;

    .pricing {
        font-size: 30px;
        text-align: center;

        p {
            margin: 0;
            padding: 0;
        }
        
        small {
            font-size: 15px;
            display: block;
            margin: 0;
        }
    }

    .actions {
        button {
            padding: 10px;
            margin-left: 15px;
        }

        @media screen and (max-width: 750px) {
            display: none;
        }
    }
}