.dashboard-page {
    .nav {
        background-color: aquamarine;
        padding: 5px;
    }

    .page {
        display: flex;

        .menu {
            width: 300px;
        }

        .content {
            width: 100%;
            background-color: blue;
            padding: 5px;
        }
    }
}

.swal2-container {
    z-index: 1000;
}